// Created by Diego Montoya

import * as THREE from "three";
import pathToTeleportShadow from "../static/teleport_shadow.png";
import pathToClickSound from "../static/click.mp3";
import { Vector3 } from "three";


let isInitialized = false;

const numberOfSegments = 100;
const forwardLength = 0.2912;
const gravityPull = 0.012;
const lineDisabledOpaicty = 0.08;
const lineEnabledOpacity = 0.37;
const lineMaterial = new THREE.LineBasicMaterial({
    color: 0xffffff,
    transparent: true,
    opacity: lineEnabledOpacity
});

let raycaster;
let points = [];
let position = new THREE.Vector3();
let scale = new THREE.Vector3();
let quaternion = new THREE.Quaternion();
const gripForwardObjectSpace = (new THREE.Vector3(0, -0.8, -1)).normalize();
let gripForward = new THREE.Vector3();
let geometry;
let line;
let shadowPlane;
let pointingToFloor = false;
let touchingTrigger = false;
let justTouchedTrigger = false;
let justPressedTrigger = false;
let justTurned = {};

let gamePads = {};
let controllers = {};
let activeController = -1;

let vrScene;
let vrCamera;
let trackingAnchor;

let raycasterLayers;
let floorLayers;

let clickSound;

function initTeleporter(scene, camera, anchor, floorLayer, colliderLayer) {
    for ( let i = 0; i < numberOfSegments; i++ ) {
        points.push( new THREE.Vector3() );
    }
    geometry = new THREE.BufferGeometry().setFromPoints( points );
    line = new THREE.Line( geometry, lineMaterial );
    line.layers.set(0);
    line.frustumCulled = false;
    line.name = 'Teleporter Line';
    scene.add(line);

    vrScene = scene;
    vrCamera = camera;
    trackingAnchor = anchor;
    initShadowPlane(scene);

    raycasterLayers = new THREE.Layers();
    raycasterLayers.set(floorLayer);
    raycasterLayers.enable(colliderLayer);
    floorLayers = new THREE.Layers();
    floorLayers.enable(floorLayer);

    const listener = new THREE.AudioListener();
    camera.add(listener);

    clickSound = new THREE.Audio(listener);

    const audioLoader = new THREE.AudioLoader();
    audioLoader.load(pathToClickSound, function(buffer) {
        clickSound.setBuffer(buffer);
        clickSound.setLoop(false);
        clickSound.setVolume(0.7);
    });

    isInitialized = true;
}

function initShadowPlane(scene) {
    const texture = new THREE.TextureLoader().load(pathToTeleportShadow);
    const geometry = new THREE.PlaneGeometry(0.67, 0.67);
    const material = new THREE.MeshBasicMaterial({
        color: 0xffffff,
        map: texture,
        transparent: true,
        opacity: lineEnabledOpacity
    });
    shadowPlane = new THREE.Mesh(geometry,material);
    shadowPlane.position.set(0, 0, -3);
    shadowPlane.rotation.set(-Math.PI / 2, 0, 0);
    shadowPlane.visible = false;
    scene.add(shadowPlane);
}

function updateTeleporter(model) {
    if ( !isInitialized ) {
        throw 'Teleporter not initialized.'
    }

    if (activeController == -1) {
        console.warn("Updating teleporter without any controller.");
        return;
    }

    checkForTrigger();

    if (!touchingTrigger) {
        shadowPlane.visible = false;
        line.visible = false;
        return;
    }

    pointingToFloor = false;
    let initPoint = new THREE.Vector3();
    controllers[activeController].getWorldPosition(initPoint);

    controllers[activeController].matrixWorld.decompose(position, quaternion, scale);
    gripForward = gripForwardObjectSpace.clone();
    gripForward.applyQuaternion( quaternion );

    const positions = line.geometry.attributes.position.array;

    let i;
    let segmentStart = initPoint.clone().add(gripForward.clone().multiplyScalar(0.05));
    let segmentEnd;
    positions[0] = segmentStart.x;
    positions[1] = segmentStart.y;
    positions[2] = segmentStart.z;

    let intersectedObject = false;
    shadowPlane.visible = false;
    line.visible = true;
    lineMaterial.opacity = lineDisabledOpaicty;

    for ( i = 1; i < numberOfSegments; i++ ) {
        const forwardWithGravity = gripForward.clone().multiplyScalar(forwardLength).sub(new THREE.Vector3(0, gravityPull * i, 0));
        segmentEnd = segmentStart.clone().add(forwardWithGravity);
        
        raycaster = new THREE.Raycaster(segmentStart, forwardWithGravity.clone().normalize(), 0, forwardWithGravity.length());
        raycaster.layers = raycasterLayers;
        const intersects = raycaster.intersectObjects(model.children, true);

        for ( let j = 0; j < intersects.length; j ++ ) {
            if (intersects[j].object.layers.test(floorLayers)) {
                let intersection = intersects[j].point.clone();
                let intersectionInLocal = vrScene.worldToLocal(intersection);
                shadowPlane.position.copy(intersectionInLocal);
                shadowPlane.visible = true;
                lineMaterial.opacity = lineEnabledOpacity;
                pointingToFloor = true;
            }
            segmentEnd = intersects[j].point;
            intersectedObject = true;
            break;
        }

        positions[i * 3] = segmentEnd.x;
        positions[i * 3 + 1] = segmentEnd.y;
        positions[i * 3 + 2] = segmentEnd.z;
        segmentStart = segmentEnd;

        if ( intersectedObject ) {
            break;
        }
    }

    for ( ; i < numberOfSegments; i++) {
        positions[i * 3] = segmentEnd.x;
        positions[i * 3 + 1] = segmentEnd.y;
        positions[i * 3 + 2] = segmentEnd.z;
    }

    line.geometry.attributes.position.needsUpdate = true;
}

function addGrip(id, model, gamePad) {
    gamePads[id] = gamePad;
    controllers[id] = model;
    activeController = id;
}

function checkForTrigger() {
    touchingTrigger = false;
    if (Object.keys(gamePads).length > 0 ) {
        for (let key in Object.keys(gamePads) ) {
            const gp = gamePads[key];
            if (activeController == key) {
                if (gp.buttons[0].touched) {
                    touchingTrigger = true;
                } else {
                    justTouchedTrigger = false;
                }
                if (!gp.buttons[0].pressed && justPressedTrigger) {
                    justPressedTrigger = false;
                }
                if (gp.buttons[0].pressed && !justPressedTrigger && pointingToFloor) {
                    let cameraWorldPosition = new THREE.Vector3();
                    vrCamera.getWorldPosition(cameraWorldPosition);
                    let difference = shadowPlane.position.clone().sub(cameraWorldPosition);
                    difference.y = 0;
                    trackingAnchor.position.add(difference);
                    justPressedTrigger = true;
                    clickSound.play();
                }
            } else {
                if (gp.buttons[0].touched) {
                    touchingTrigger = true;
                    if (!justTouchedTrigger) {
                        activeController = key;
                        justTouchedTrigger = true;
                    } 
                } else {
                    justTouchedTrigger = false;
                }
                if (gp.buttons[0].pressed) {
                    justPressedTrigger = true;
                }
            }
            if ((gp.axes[2] > 0.5 || gp.axes[2] < -0.5) && !justTurned[key]) {
                justTurned[key] = true;
                const angle = Math.sign(gp.axes[2]) * -30 * Math.PI / 180;
                trackingAnchor.rotateOnAxis(new Vector3(0, 1, 0), angle);
                clickSound.play();
            }
            if (gp.axes[2] < 0.5 && gp.axes[2] > -0.5 && justTurned[key]) {
                justTurned[key] = false;
            }
        }
    }
}

export {initTeleporter, updateTeleporter, addGrip}