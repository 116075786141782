// Created by Diego Montoya

import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import * as THREE from "three";
import {XRControllerModelFactory} from "three/examples/jsm/webxr/XRControllerModelFactory";
import {TWEEN} from 'three/examples/jsm/libs/tween.module.min'
import {VRButton} from './VRButton.js';
import {updateTeleporter, initTeleporter, addGrip} from "./teleporter";

export {init}

let container;
let trackingSpaceAnchor;
let camera, scene, renderer;
let dirLight, ambLight;
let controllerGripLeft, controllerGripRight;
let controllerDetected = false;

let model;
let loader = new GLTFLoader();

const modelLayer = 0;
const floorLayer = 1;
const colliderLayer = 2;

let animationTweens = [];
const wireBaseDelay = 500;
const wireMaxDelay = 6810;
const wireTransitionTime = 2790;
const wireStayTime = 900;
const textureBaseDelay = 0;
const textureTransitionTime = 6280;


function loadModel(scene, path, callback) {
    loader.load( path, function ( gltf ) {
        scene.add( gltf.scene );
        model = gltf.scene;
        model.position.set(0, 0, 0);
        setModelLayers(model);
        initIntro(model);

        callback();

        animate();
    }, function ( xhr ) {
        console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
    }, function ( error ) {
        console.error( error );
    } );
}

function setModelLayers(model) {
    model.children.forEach(child => {
        if (child.name.slice(0).toLowerCase().includes("floor")) {
            child.layers.set(floorLayer);
        } else if (child.name.slice(0).toLowerCase().includes("collider")) {
            child.layers.set(colliderLayer);
        } else {
            child.layers.set(modelLayer);
        }
        setModelLayers(child);
    });
}

function initIntro(model) {
    animationTweens.forEach(t => t.stop());
    animationTweens = [];
    model.children.forEach(child => {
        if (!child.name.slice(0).toLowerCase().includes("floor")
        && !child.name.slice(0).toLowerCase().includes("collider")) {
            let wireDelay = wireBaseDelay + Math.random() * wireMaxDelay;
            let tweenWireFadein = wireFade(child, wireDelay, true);
            let tweenWireFadeout = wireFade(child, wireStayTime, false);
            
            child.material.wireframe = true;
            child.material.color = new THREE.Color(0, 0, 0);
            child.material.transparent = true;
            child.material.opacity = 0;
            
            let textureDelay = wireBaseDelay + wireMaxDelay + wireTransitionTime
                + wireStayTime + wireTransitionTime
                + textureBaseDelay;
            let tweenTexture = textureFade(child, textureDelay, true);
            
            tweenWireFadein.chain(tweenWireFadeout);
            animationTweens.push(tweenWireFadein);
            animationTweens.push(tweenTexture);
        }
    });
}

function wireFade(object, delay, isIn) {
    let tween = fade(object, delay, isIn, wireTransitionTime, true, TWEEN.Easing.Quadratic.InOut);
    return tween;
}

function textureFade(object, delay, isIn) {
    let tween = fade(object, delay, isIn, textureTransitionTime, false, TWEEN.Easing.Quartic.In);
    return tween;
}

function fade(object, delay, isIn, transitionTime, wireframe, easing) {
    let color;
    let endColor;
    if (isIn) {
        color = new THREE.Color(0, 0, 0);
        endColor = new THREE.Color(1, 1, 1);
    } else {
        color = new THREE.Color(1, 1, 1);
        endColor = new THREE.Color(0, 0, 0);
    }
    let tween = new TWEEN.Tween(color).to(endColor)
    .duration(transitionTime)
    .delay(delay)
    .onUpdate(() => {
        object.material.color = color;
        object.material.wireframe = wireframe;
        object.material.transparent = wireframe;
        object.material.opacity = color.r;
    })
    .easing(easing);

    return tween;
}

function init(pathToModel, callback) {

    container = document.createElement( 'div' );
    document.body.appendChild( container );

    scene = new THREE.Scene();
    scene.background = new THREE.Color( 0x000000 );

    trackingSpaceAnchor = new THREE.Group();
    trackingSpaceAnchor.position.set(0, 0, 0);
    camera = new THREE.PerspectiveCamera( 95, window.innerWidth / window.innerHeight, 0.1, 100 );
    camera.position.set(0, 1.53, 0);
    trackingSpaceAnchor.add(camera);
    scene.add(trackingSpaceAnchor);


    dirLight = new THREE.DirectionalLight(0x676767);
    dirLight.position.set(0, 1, 0).normalize();
    scene.add(dirLight);

    ambLight = new THREE.AmbientLight(0x202020); // soft white light
    scene.add(ambLight);

    renderer = new THREE.WebGLRenderer( { antialias: true } );
    renderer.setPixelRatio( window.devicePixelRatio );
    renderer.setSize( window.innerWidth, window.innerHeight );
    renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.xr.enabled = true;
    container.appendChild( renderer.domElement );

    let controllerModelFactory = new XRControllerModelFactory();
    controllerGripLeft = renderer.xr.getControllerGrip(0);
    controllerGripLeft.add(controllerModelFactory.createControllerModel(controllerGripLeft));


    controllerGripLeft.addEventListener("connected", (e) => {
        addGrip(0, controllerGripLeft, e.data.gamepad);
        controllerDetected = true;
    });

    trackingSpaceAnchor.add( controllerGripLeft );

    controllerGripRight = renderer.xr.getControllerGrip(1);
    controllerGripRight.add( controllerModelFactory.createControllerModel(controllerGripRight));

    controllerGripRight.addEventListener("connected", (e) => {
        addGrip(1, controllerGripRight, e.data.gamepad);
        controllerDetected = true;
    });

    trackingSpaceAnchor.add( controllerGripRight );

    window.addEventListener( 'resize', onWindowResize, false );
    renderer.xr.addEventListener( 'sessionend', OnXRSessionEnd );

    
    loadModel(scene, pathToModel, () => {
        let vrButton = VRButton.createButton( renderer );
        vrButton.addEventListener( 'click', OnXRSessionStart );
        document.body.appendChild( vrButton );
        callback();
    });
    initTeleporter(scene, camera, trackingSpaceAnchor, floorLayer, colliderLayer);
}

function animate() {
    renderer.setAnimationLoop(render);
}

function render() {
    TWEEN.update();
    
    if (controllerDetected) {
        updateTeleporter(model);
    }
    renderer.render(scene, camera);
}

function OnXRSessionEnd() {

}

function OnXRSessionStart() {
    renderer.getContext("webgl").makeXRCompatible();
    initIntro(model);
    animationTweens.forEach(t => t.start());
}

function onWindowResize() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize( window.innerWidth, window.innerHeight );
}