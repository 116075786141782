import './style.css'
import pathToLogo from "../static/thehaus_logo.png";
import pathToSpinner from "../static/spinner.png";


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
// Todo: Setup proper dotenv environment handling with webpack
//import {firebaseConfig} from "./firebase-config.dev";
import {firebaseConfig} from "./firebase-config.production";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


const { initializeAppCheck, ReCaptchaV3Provider } = require("firebase/app-check");


// import * as dat from 'dat.gui'
import {init} from './vr-player'


// Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
/*const firebaseConfig = {
    //apiKey: FIREBASE_API_KEY,
    apiKey: "AIzaSyBqrsQnJBCtLRK8m_l3Zvk9wHVjoN2hO2I",
    authDomain: "the-haus-nft-dev.firebaseapp.com",
    projectId: "the-haus-nft-dev",
    storageBucket: "the-haus-nft-dev.appspot.com",
    messagingSenderId: "337970012986",
    appId: "1:337970012986:web:2876b54d8e5e6d57b0e603",
    measurementId: "G-WWHEYCXQKH"
};*/

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
// Initialize Firebase Functions
const functions = getFunctions(app, 'europe-west1');
const signInWithOtp = httpsCallable(functions, "signInWithOtp");
// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
/*const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider('6LerX1YcAAAAAHYNiIPz3a_hla_LGQaprvu743Nm'),

    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true
});*/
// Debug
// const gui = new dat.GUI()

const divs = Array.from(document.getElementsByTagName('div')).filter(div => div.className != "spinner-div");
const inputs = document.getElementsByClassName('otp-input');
const text = document.getElementsByClassName('text-div')[0];
let spinner;

let otp = '';

addValidators();

/***************************
 * 
 * Patch to make logo and spinner images work
 * 
 * ************************/
(function logoPatch() {
    let logoDiv = document.getElementsByClassName("logo-div")[0];
    let image = document.createElement('img');
    image.className = "logo-img";
    image.src = pathToLogo;
    logoDiv.appendChild(image);
})();

(function spinnerPatch() {
    const spinnerDiv = document.getElementsByClassName("spinner-div")[0];
    const image = document.createElement('img');
    image.className = "spinner-img";
    image.src = pathToSpinner;
    spinnerDiv.appendChild(image);
}) ();
/***************************/

function addValidators() {
    for (var i = 0; i < inputs.length; i++) {
        inputs[i].addEventListener('input', processInput);
        inputs[i].onkeydown = validateBackspace;
    }
}

function processInput(evt) {
    let theEvent = evt || window.event;
    if (theEvent.inputType == "insertFromPaste") {
        let target = theEvent.target;
        for(let i = 0; i < theEvent.data.length; i++) {
            if (target) {
                target = validateInput(theEvent.data[i], target);
            }
        }
    } else {
        validateInput(theEvent.data, theEvent.target);
    }
}

function validateInput(key, target) {
    let regex = /[0-9A-Za-z]/;
    if(key != null && regex.test(key)) {
        target.value = key.toUpperCase();
        registerInput();
        return nextField();
    } else {
        target.value = '';
        return target;
    }
}

function validateBackspace(evt) {
    if (evt.key == "Backspace" || evt.keyCode == 8) {
        previousField();
    }
}

function registerInput() {
    var temp = '';
    for (var i = 0; i < inputs.length; i++) {
        temp += inputs[i].value;
    }
    otp = temp;
}

function nextField() {
    for (var i = 0; i < inputs.length; i++) {
        if (document.activeElement.id == 'input6') {
            document.activeElement.blur();
            submit(otp);
            break;
        }
        if (document.activeElement.id == inputs[i].id && i+1 < inputs.length) {
            inputs[i+1].focus();
            return inputs[i+1];
        }
    }
}

function previousField() {
    for (var i = 1; i < inputs.length; i++) {
        if (document.activeElement.id == inputs[i].id) {
            inputs[i-1].focus();
            break;
        }
    }
}

const submit = async otp => {
    /******************************************************************************/
    /* 
     * Here is where the OTP has to be submitted to the backend
     * and the returned URL used as parameter for init(URL)
    */ 
    console.log("@submit::otp", otp);
    try{
        showUI(false);
        showSpinner(true);

        /* sign in with OTP */
        const res = await signInWithOtp({otp:otp});
        // console.log("token", res.data.token);
        // console.log("data", res.data.res);
        const token = res.data.token;

        /* sign in with custom firebase token */
        const auth = getAuth();
        const userCredential = await signInWithCustomToken(auth, token);
        /*const user = userCredential.user;
        console.log(user);*/
        const currentUser = await auth.currentUser;

        /* getIdTokenResult for debug only*/
        const idTokenResult = await currentUser.getIdTokenResult();
        console.log("@submit::getIdTokenResult::claims.roomExperiences", idTokenResult.claims.roomExperiences);

        /* getting storageUrl for Vr model */
        const storage = getStorage();
        const modelUrl = await getDownloadURL(ref(storage, res.data.res.modelGltf));
        /* start experience */
        init(modelUrl, () => showSpinner(false));
    } catch(error){
        /* Getting the Error details */
        switch (error.code) {
            case 'functions/failed-precondition':
                if (error.details.message == 'No OTP available') {
                    showSpinner(false);
                    showUI(true);
                    displayMessageAndClearInputs('Invalid code, try again:', 'red');
                }
                break;
            case 'storage/object-not-found':
                // File doesn't exist
            case 'storage/unauthorized':
                // User doesn't have permission to access the object
            case 'storage/canceled':
                // User canceled the upload
            case 'storage/unknown':
                // Unknown error occurred, inspect the server response
                showSpinner(false);
                showUI(true);
                displayMessageAndClearInputs('Server error, try again:', 'red');
                break;
        }
        console.log(error.code);
        console.log(error.message);
        console.log(error.details);
    }
    /******************************************************************************/
}

function displayMessageAndClearInputs(message, color) {
    text.innerHTML = message;
    text.style.color = color;

    for (let i = 0; i < inputs.length; i++) {
        inputs[i].value = '';
    }
    inputs[0].focus();
}

function showSpinner(value) {
    if (spinner == null) {
        spinner = document.getElementsByClassName("spinner-img")[0];
    }
    const v = (value ? "visible" : "hidden");
    spinner.style.visibility = v;
}

function showUI(value) {
    const v = (value ? "visible" : "hidden");
    for (let i = 0; i < divs.length; i++) {
        divs[i].style.visibility = v;
    } 
}