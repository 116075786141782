// Modified by Diego Montoya

class VRButton {
	static createButton(renderer, options) {
		if (options) {

			console.error('THREE.VRButton: The "options" parameter has been removed. Please set the reference space type via renderer.xr.setReferenceSpaceType() instead.');

		}

		const button = document.createElement('button');
		button.className = 'vrbutton';
		button.textContent = 'Enter VR';

		function showEnterVR() {

			let currentSession = null;

			function onSessionStarted(session) {
				session.addEventListener('end', onSessionEnded);

				renderer.xr.setSession(session);
				button.textContent = 'Exit VR';

				currentSession = session;
			}

			function onSessionEnded() {
				currentSession.removeEventListener('end',onSessionEnded);

				button.textContent = 'Enter VR';

				currentSession = null;
			}

			button.onmouseenter = function () {
				button.style.opacity = '1.0';
			};

			button.onmouseleave = function () {
				button.style.opacity = '0.7';
			};

			button.onclick = function () {
				if ( currentSession === null ) {

					// WebXR's requestReferenceSpace only works if the corresponding feature
					// was requested at session creation time. For simplicity, just ask for
					// the interesting ones as optional features, but be aware that the
					// requestReferenceSpace call will fail if it turns out to be unavailable.
					// ('local' is always available for immersive sessions and doesn't need to
					// be requested separately.)

					const sessionInit = {optionalFeatures:['local-floor', 'bounded-floor', 'hand-tracking']};
					navigator.xr.requestSession('immersive-vr', sessionInit).then(onSessionStarted);
				} else {
					currentSession.end();
				}
			};

		}

		function showWebXRNotFound() {
			button.style.opacity = '0.7';
			button.onmouseenter = null;
			button.onmouseleave = null;
			button.textContent = 'VR not supported';
		}

		if ( 'xr' in navigator ) {
			button.className = 'vrbutton';
			navigator.xr.isSessionSupported( 'immersive-vr' ).then( function ( supported ) {
				supported ? showEnterVR() : showWebXRNotFound();
			} );

			return button;
		} else {
			const message = document.createElement('a');
			message.className = 'vrbutton';
			
			if ( window.isSecureContext === false ) {
				message.href = document.location.href.replace( /^http:/, 'https:' );
				message.innerHTML = 'WebXR requires HTTPS, click to refresh';
				// message.style.width = '360px';
				message.style.textDecoration = 'none';
			} else {
				message.innerHTML = 'VR not supported:<br>You need a VR device to enter the experience';
				message.style.color = 'rgba(255,255,255,0.7)';
				message.style.border = '1px solid rgba(255,255,255,0.7)';
			}

			return message;
		}
	}
}

export { VRButton };
